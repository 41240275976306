.player-wrapper {
  position: relative;
  padding-top: 0%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: relative;
  top: 0;
  left: 0;
}
